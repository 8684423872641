<template>
  <div>
    <div class="page-company row">
      <b-modal
        :cancel-title="$gettext('Cancel')"
        :title="$gettext('Edit company logo')"
        id="edit-logo-modal"
        @ok="handleLogoUpdate"
      >
        <FormGroupFile
          :label="$gettext('Logo')"
          field="logo"
          v-model="logo"
          accept="image/jpeg, image/png"
          required
        />
      </b-modal>
      <b-modal
        :title="$gettext('Edit company banner')"
        :cancel-title="$gettext('Cancel')"
        id="edit-banner-modal"
        @ok="handleBannerUpdate"
      >
        <FormGroupFile
          :label="$gettext('Banner')"
          field="banner"
          v-model="banner"
          accept="image/jpeg, image/png"
          required
        />
      </b-modal>

      <div class="col-md-4">
        <div class="card card-user">
          <div class="company-banner image">
            <img v-if="company.banner" :src="company.banner" alt="..." />
          </div>
          <div class="card-body">
            <div class="author">
              <img
                class="company-logo rounded border border-light bg-white position-relative overflow-hidden p-4 mb-4"
                :src="company.logo"
                alt="Company logo"
              />
              <h5 class="title">{{ company.name_fr }}</h5>
            </div>
            <p
              v-html="company.description_fr"
              class="description text-center"
            ></p>
          </div>
          <div class="cart-footer text-center">
            <a :href="careerPageUrl" target="_blank" class="btn mr-1">
              <i class="fas fa-eye mr-2"></i>
              <translate>career page</translate>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="title"><translate>Company</translate></h5>
              <div>
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="showEditLogoModal"
                >
                  <i class="fas fa-edit mr-2"></i>
                  <translate>Edit logo</translate>
                </b-button>
                <b-button variant="primary" @click="showEditBannerModal">
                  <i class="fas fa-edit mr-2"></i>
                  <translate>Edit banner</translate>
                </b-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleUpdate(payload)">
              <div class="row">
                <div class="col-md-6">
                  <FormInput
                    field="name_fr"
                    v-model="payload.name_fr"
                    :label="$gettext('Company name')"
                    required
                  />
                </div>
                <div class="col-md-6">
                  <FormInput
                    field="website"
                    v-model="payload.website"
                    :label="$gettext('Website')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <FormInput
                    field="email"
                    v-model="payload.email"
                    :label="$gettext('Email address')"
                  />
                </div>
                <div class="col-md-4">
                  <FormInput
                    field="phone_number"
                    v-model="payload.phone_number"
                    :label="$gettext('Phone number')"
                  />
                </div>
                <div class="col-md-4">
                  <FormInput
                    field="employee_count"
                    v-model="payload.employee_count"
                    :label="$gettext('Number of employees')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <Editor
                    v-model="payload.description_fr"
                    :label="$gettext('Description')"
                    field="description_fr"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <FormGroupGmapAutocomplete
                    :label="$gettext('Address')"
                    field="address"
                    v-model="payload.address"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <FormInput
                    field="linkedin_url"
                    v-model="payload.linkedin_url"
                    :label="$gettext('Linkedin')"
                  />
                </div>
                <div class="col-md-6">
                  <FormInput
                    field="facebook_url"
                    v-model="payload.facebook_url"
                    :label="$gettext('Facebook')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <FormInput
                    field="twitter_url"
                    v-model="payload.twitter_url"
                    :label="$gettext('Twitter')"
                  />
                </div>
                <div class="col-md-6">
                  <FormInput
                    field="instagram_url"
                    v-model="payload.instagram_url"
                    :label="$gettext('Instagram')"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <router-link
                    :to="{ name: 'companies' }"
                    href="interview.html"
                    class="btn btn-danger"
                    ><translate>Cancel</translate></router-link
                  >
                  <SubmitButton
                    :label="$gettext('Update')"
                    class="btn btn-primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-8 offset-4">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="title"><translate>Indeed XML feed</translate></h5>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleUpdateFeed()">
              <div class="row">
                <div class="col-md-12">
                  <FormGroupCheckbox
                    field="is_feed_enabled"
                    v-model="feed_payload.is_feed_enabled"
                    :label="$gettext('Enable XML feed')"
                  ></FormGroupCheckbox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <FormInput
                    field="indeed_account_email"
                    v-model="feed_payload.indeed_account_email"
                    :label="
                      $gettext(
                        'Email account associated with the Indeed account for this company.'
                      )
                    "
                  />
                </div>
              </div>
              <div class="row">
                <div v-if="showFeedIsPending" class="col-md-12">
                  <p>
                    {{
                      $gettext(
                        "We are processing your XML feed. A link will appear here once the file is available."
                      )
                    }}
                  </p>
                </div>
                <div
                  v-else-if="company.xml_feed_is_available"
                  class="col-md-12"
                >
                  <a target="_blank" :href="company.feed_cdn"
                    ><translate>Access the XML feed</translate></a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <router-link
                    :to="{ name: 'companies' }"
                    href="interview.html"
                    class="btn btn-danger"
                    ><translate>Cancel</translate></router-link
                  >
                  <SubmitButton
                    :label="$gettext('Update')"
                    class="btn btn-primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @todo enable form once data is fetched. Or pass company as prop
import { mapActions, mapGetters } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import FormGroupGmapAutocomplete from "@/components/FormGroups/FormGroupGmapAutocomplete";
import Editor from "@/components/FormGroups/Editor";
import SubmitButton from "@/components/SubmitButton";
import FormGroupFile from "@/components/FormGroups/FormGroupFile";
import { scrollToFirstElement } from "@/utils/scrollTo";
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox.vue";
export default {
  components: {
    FormGroupCheckbox,
    Editor,
    FormGroupGmapAutocomplete,
    FormInput,
    FormGroupFile,
    SubmitButton
  },
  data: () => ({
    feedIsPending: false,
    payload: {
      name_fr: "",
      description_fr: "",
      website: "",
      email: "",
      phone_number: "",
      employee_count: "",
      address: "",
      linkedin_url: "",
      facebook_url: "",
      twitter_url: "",
      instagram_url: ""
    },
    feed_payload: {
      is_feed_enabled: false,
      indeed_account_email: ""
    },
    logo: [],
    banner: []
  }),
  props: {
    companyId: { type: [String, Number] }
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchOne(to.params.companyId)
      .then(() => {
        Object.assign(this.payload, this.company);
        this.payload.address = this.company.location.address;
        Object.assign(this.feed_payload, {
          is_feed_enabled: this.company.is_feed_enabled,
          indeed_account_email: this.company.indeed_account_email
        });
      })
      .finally(this.stopFetching);
    next();
  },
  created() {
    this.fetchOne(this.companyId)
      .then(() => {
        Object.assign(this.payload, this.company);
        this.payload.address = this.company.location.address;
        Object.assign(this.feed_payload, {
          id: this.companyId,
          is_feed_enabled: this.company.is_feed_enabled,
          indeed_account_email: this.company.indeed_account_email
        });
      })
      .finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("companies", { company: "item" }),
    ...mapGetters("me", ["lg"]),
    careerPageUrl() {
      return this.lg === "fr"
        ? this.company.career_pages?.fr
        : this.company.career_pages?.en;
    },
    showFeedIsPending() {
      console.log(
        this.feedIsPending ||
          (this.company.is_feed_enabled && !this.company.xml_feed_is_available)
      );
      return (
        this.feedIsPending ||
        (this.company.is_feed_enabled && !this.company.xml_feed_is_available)
      );
    }
  },
  methods: {
    ...mapActions("companies", [
      "fetchOne",
      "update",
      "updateCompanyImage",
      "updateFeed"
    ]),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("form", ["submit"]),
    handleUpdate() {
      this.submit(() => this.update(this.payload))
        .then(() =>
          this.$toast.open({
            message: "Done!",
            type: "success"
          })
        )
        .catch(() => scrollToFirstElement(".page-company form .is-invalid"));
    },
    handleUpdateFeed() {
      this.submit(() => this.updateFeed(this.feed_payload))
        .then(() => {
          this.$toast.open({
            message: "Done!",
            type: "success"
          });
          this.feedIsPending = true;
        })
        .catch(() => scrollToFirstElement(".page-company form .is-invalid"));
    },
    showEditLogoModal() {
      this.$bvModal.show("edit-logo-modal");
    },
    showEditBannerModal() {
      this.$bvModal.show("edit-banner-modal");
    },
    handleLogoUpdate() {
      const form = new FormData();
      form.append("field", "logo");
      form.append("image", this.logo);
      this.updateCompanyImage({ id: this.companyId, payload: form }).then(() =>
        this.$router.go(0)
      );
    },
    handleBannerUpdate() {
      const form = new FormData();
      form.append("field", "banner");
      form.append("image", this.banner);
      this.updateCompanyImage({ id: this.companyId, payload: form }).then(() =>
        this.$router.go(0)
      );
    }
  }
};
</script>
